import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Image, MenuProps, Space, TablePaginationConfig } from 'antd';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import dayjs from 'dayjs';
import { NotificationFilterFormPaginated } from 'features/notification/components/notification-filter-form/type';
import { useCreateNotificationSend } from 'features/notification/use-cases/create-notification-send';
import { useDeleteNotification } from 'features/notification/use-cases/delete-notification';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Notifications } from 'types/notification';
type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: NotificationFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<NotificationFilterFormPaginated>>;
  refetch: () => void;
};

export const NotificationViewTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const { mutate } = useCreateNotificationSend();

  const { mutate: deleteMutate } = useDeleteNotification();

  if (!permissions) return null;
  const handleTableChange = (pagination: TablePaginationConfig) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
    });
  };
  const handlesend = (Id: string) => {
    const formData = {
      id: Id,
    };
    mutate(formData, { onSuccess: refetch }); // Assuming mutate is defined elsewhere
  };

  const handleDelete = (Id: string) => {
    const formData = {
      id: Id,
    };
    deleteMutate(formData, { onSuccess: refetch }); // Assuming mutate is defined elsewhere
  };

  const items = (record: any): MenuProps['items'] => {
    const menuItems = [
      {
        label: 'Preview',
        key: '1',
        onClick: () => {
          navigate(getRoute(ROUTES.NOTIFICATION_DETAIL, record.Id));
          document.body.style.overflow = 'hidden';
        },
      },
      ...(record.IsSend
        ? []
        : [
            {
              label: 'Send',
              key: '2',
              onClick: () => handlesend(record.Id), // Pass the notification id to the send function
            },
            {
              label: 'Edit',
              key: '0',
              onClick: () => {
                navigate(getRoute(ROUTES.NOTIFICATION_EDIT, record.Id)); // Navigate to edit route
                document.body.style.overflow = 'hidden';
              },
            },
            {
              label: 'Delete',
              key: '3',
              onClick: () => handleDelete(record.Id),
            },
          ]),
    ];

    return menuItems;
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={'Notification Found'}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Notifications>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='Id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
      >
        <Column<Notifications>
          title={'Image'}
          dataIndex={'image'}
          render={(_, record: any) => (
            <Image
              src={record?.Image}
              alt='Package Image'
              width={50}
              height={50}
              fallback='https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'
            /> // Adjust size as needed
          )}
        />
        <Column<Notifications>
          title={'Device Type'}
          dataIndex={'DeviceType'}
          // width={screens.xxl ? 'auto' : 170}
        />
        <Column<Notifications>
          title={'Title'}
          dataIndex={'Title'}
          ellipsis
          // width={screens.xxl ? 'auto' : 130}
        />
        <Column<Notifications>
          width={80}
          title={'Send'}
          dataIndex={'IsSend'}
          render={(_, record: any) => (
            <span>{record.IsSend ? 'Yes' : 'No'}</span>
          )}
        />

        <Column<Notifications>
          width={200}
          title={'Create Date'}
          dataIndex={'CreatedOn'}
          render={(_, record: any) => (
            <span>
              {record.CreatedOn &&
                dayjs(record.CreatedOn).format('DD-MM-YYYY hh:mm A')}
            </span>
          )}
        />

        <Column<Notifications>
          width={200}
          title={'Send Date'}
          dataIndex={'SendOn'}
          render={(_, record: any) => (
            <span>
              {record.SendOn &&
                dayjs(record.SendOn).format('DD-MM-YYYY hh:mm A')}
            </span>
          )}
        />

        <Column<Notifications>
          width={80}
          title={t('package.table.action')}
          dataIndex={'last_transaction_date'}
          className='action_col'
          render={(_, record: any) => (
            <Space>
              <Dropdown menu={{ items: items(record) }} trigger={['click']}>
                <a
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click when dropdown is clicked
                    e.preventDefault();
                  }}
                >
                  <Space className='dot_icon_drop'>
                    <MoreOutlined style={{ fontWeight: '600' }} />
                  </Space>
                </a>
              </Dropdown>
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
