import { Form, Space, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useCreateApproved } from 'features/withdraw/use-cases/create-approved';
import { useGetWithdrawTransactionDetails } from 'features/withdraw/use-cases/get-withdraw-details';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
// ../users-with-wallet-filter-form/type
export const WithdrawViewModal = ({
  onClose,
  id,
  onSuccess,
}: {
  onClose: () => void;
  id: string;
  onSuccess: any;
}) => {
  // const { t } = useTranslation();
  const userQueryResult: any = useGetWithdrawTransactionDetails(`id=${id}`, {
    cacheTime: 0,
  });
  const [form] = Form.useForm();
  const {
    isLoading: isLoadingGetSocial,
    isFetching: isFetchingGetSocial,
    data: withdrawdata,
    isError: isErrorLabel,
  } = userQueryResult;
  const isLoading = {
    loading: isLoadingGetSocial || isFetchingGetSocial,
    isLoadingGetSocial,
  };
  const { mutate: mutateUpdateapproved } = useCreateApproved();
  const handleSubmit = (formdata: any) => {
    const dataToSubmit = {
      id, // assuming id is defined in the scope
      approved: 2,
      user_name: withdrawdata?.items[0].user_name,
      phone_number: withdrawdata?.items[0].phone_number,
      status_id: 2,
      ...formdata,
    };

    mutateUpdateapproved(dataToSubmit, { onSuccess });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='modal_social right-modal mtch_dtls_modal'
        open
        centered
        title={'Withdraw Details'}
        onCancel={() => {
          document.body.style.overflow = 'none';
          onClose();
        }}
        getContainer={false}
        footer={null}
        // onOk={{ style: { display: 'none' } }}
        // okText={t('social.update.updatescore')}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {isLoadingGetSocial ? (
          <Loader />
        ) : isErrorLabel ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form onFinish={handleSubmit} form={form}>
              <div className='wd_dta_modal'>
                <div className='wd_user'>
                  <p>
                    <span>User Name:</span>
                    <span>{withdrawdata?.items[0].user_name}</span>
                  </p>
                  <p>
                    <span>Phone Number:</span>
                    <span>{withdrawdata?.items[0].phone_number}</span>
                  </p>
                  <p>
                    <span>Requested Amount:</span>
                    <span>{withdrawdata?.items[0].amount} KD</span>
                  </p>
                  <p>
                    <span>Requested Date:</span>
                    <span>
                      {formatDate({
                        date: withdrawdata?.items[0].request_date,
                        format: 'date',
                        timeZone: DEFAULT_TIMEZONE,
                      } as any)}
                      <Space style={{ padding: '2px' }}>
                        {formatDate({
                          date: withdrawdata?.items[0].request_date,
                          format: 'time',
                          timeZone: DEFAULT_TIMEZONE,
                        } as any)}
                      </Space>
                    </span>
                  </p>
                  <p>
                    <span>Status:</span>
                    <span>{withdrawdata?.items[0].status_name}</span>
                  </p>
                  <p>
                    <span>Action By:</span>
                    <span>{`${withdrawdata?.items[0].action_by}`}</span>
                  </p>
                  <p>
                    <span>Action On:</span>
                    <span>
                      {formatDate({
                        date: withdrawdata?.items[0].action_date,
                        format: 'date',
                        timeZone: DEFAULT_TIMEZONE,
                      } as any)}
                      <Space style={{ padding: '2px' }}>
                        {formatDate({
                          date: withdrawdata?.items[0].action_date,
                          format: 'time',
                          timeZone: DEFAULT_TIMEZONE,
                        } as any)}
                      </Space>
                    </span>
                  </p>

                  <p>
                    <span>Remark:</span>
                    <span>
                      {withdrawdata?.items[0].remarks == null
                        ? 'N/A'
                        : withdrawdata?.items[0].remarks}
                    </span>
                  </p>
                </div>
              </div>
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
